import React from "react"
import { Stack, Main } from "@layout"
import PageTitle from "../components/Shared/PageTitle"
import Divider from "@components/Divider"
import "./animation.css"


export default function () {
  const subheader = `
Introduction

This Privacy Policy was updated on or about February 19, 2021. Data Collaborative Inc and its affiliated companies and subsidiaries (collectively referred to as “Data Collaborative,” “we,” “our” or “us”) respect your privacy. If you have any questions about this privacy policy, or our practices with regards to your privacy or personal information, please contact us at info@datacollaborative.com

When you visit our website (www.datacollaborative.com or www.quickbaseconsulting.com) or use any of our Products or Services, we appreciate that you are trusting us with your personal information. In this notice we seek to describes how we collect Information from and about you, what we do with the that information, including how information is shared, and how the content is used related to Products and Services.  By providing Content, Information or Personal Information to us including on our website or purchasing our Products or Services, or signing an agreement that incorporates this Privacy Policy by reference you agree to this Privacy Policy.

Fundamentally, we collect the personal information such as: name, address, email address, phone number, social security number, employment-related information, education data, that you provide and information that is automatically collected including online identifiers, such as Internet Protocol address and/or browser and device characteristics, last log-in, referring URL, device name, country, location, information about how and when you use our Website, Product or Service and other technical information.  

This information is primarily needed to maintain security and normal operations of our Website, Product or Service, and for internal reporting and analysis. We also collect and use information necessary to handle requests for products or services as a customer, including domain name, user name and IP address of individual using the product or service; credit card information (when purchasing a product or service) or contact us for help.  In short, we use the information collected to conduct our legitimate business, the fulfillment of the Product and Services that are purchased, and compliance with our legal obligations. 

We collect information including but not limited to the following: pre-sales activities and correspondence with you as a prospective or as a current or past customer.  The specific information collected is dependent on your usage of our website, include forms and purchased or licensed Product and Services. 

How We Use Your Information and Personal Information

We may use your Personal Information for the following business purposes:
    •	To deliver the Products and Services that you have purchased, licensed, or inquired about including software updates
    •	To operate, benchmark, and improve our internal operations, systems, products and services
    •	To manage our relationship with you as our customer and provide you with help and customer support, including response to your inquiries
    •	To perform research and analysis the use of, or interest in, our products, services or content
    •	To improve our ability to send or share useful information or to customize your experience
    •	To conduct commercial activities including orders, invoices, notices, technical updates, alerts, support, administrative, and marketing message. 
    •	To generally communicate with you about your experience with our Product or Services

Our Disclosure of Your Personal Information to Third Parties

We may share your Personal Information with third parties for the following business purposes:
    •	When we have your permission, including when you choose to share your Personal Information for a review or blog 
    •	When necessary to conduct business in normal course interactions with agents, partners, contractors, vendors or service providers who perform functions on our behalf.

We may share your information with our current or future “affiliates” (which means a parent company, any subsidiaries, joint ventures, or other companies under common control), in which case we will require our affiliates to honor this Privacy Policy.

We may disclose Personal Information to comply with laws or in response to legal process, for example, in response to a court order or a subpoena. We also may disclose Personal Information in response to a law enforcement agency’s request.

To detect security incidents including when it is necessary to investigate, verify, prevent, enforce compliance with, or take action regarding: illegal or suspected illegal activities; suspected fraud; situations involving potential threats to the physical safety of any person; protection of the rights and property of Data Collaborative, our agents, customers or others; or violations or suspected violations of our agreements, policies or end user license agreements; or as otherwise required or permitted by law, or consistent with legal requirements. We reserve the right to transfer or disclose Personal Information in our system relating to or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or other proceeding involving sale, transfer, divestiture or assignment of all or a portion of our business or assets to a different entity.

For auditing purposes including the interaction with the customer and for compliance.

Finally, we may also share aggregated, anonymized or statistical information, including demographics data, with others for a variety of purposes, including for our own uses, for example, for improving their Products and Services.

Children’s Privacy

Our Products and Services are not designed to appeal to minors and we do not knowingly receive any information from anyone under the age of 13. If this is a concern, please contact us immediately at info@datacollaborative.com.

End Customer Data

Our customers may electronically submit Information to one of our Products or Service for processing and storage purposes (“End Customer Data”). In this instance, we function as a service provider and may access or process End Customer Data only for the purpose of providing the product or service or preventing or addressing service or technical problems or as may be required by law.

If your Personal Information has been submitted to us by a Data Collaborative customer and you wish to exercise any rights you may have to access, correct, amend, or delete such data, please inquire with the relevant customer directly. 

Your Data Subject Rights

Your Access, Amendment and Deletion of your Personal Information for all data subjects:

We allow you to access, correct or delete your Personal Information with proper identification verification. We may not disclose or delete data if an exemption or exception to deletion exists. You can submit your request by sending an email to info@datacollaborative.com.

Our marketing emails tell you how to “opt-out” of receiving further marketing emails. If you opt out, we may still send you non-marketing emails including emails about your accounts and our business dealings with you.

For Residents of California: 

The California Consumer Privacy Act (CCPA) provides privacy protections to California consumers as follows:

We do not sell your personal information, so we do not provide an opt-out page or option.

Rights to California resident under data protection law include right to access and right to request data collected about you, including categories of information collected about you and categories of sources from which information is collected about you and business or commercial purpose for collecting or selling personal information and categories of third parties with whom we share data.

For more and up-to-date information please see https://oag.ca.gov/sites/all/files/agweb/pdfs/privacy/oal-sub-final-text-of-regs.pdf

Our Retention of your Personal Information

Personal Information will be retained as long as needed to fulfill legitimate business purposes for which it was collected, including the purposes outlined in this Privacy Policy, or for a period of time specifically required or allowed by applicable regulations or laws. We will also retain and use your Personal Information for as long as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.

Security

We have put in place reasonable and appropriate physical, electronic, and managerial procedures in an effort to help safeguard Personal Information we collect. However, no organization cannot fully eliminate security risks associated with Personal Information. 

Changes to This Privacy Policy

We review our Privacy Policy from time to time.  As appropriate we will make changes to this policy and change the “Last Updated” date. Unless additional notice or consent is required by applicable laws, this will serve as notification of these changes.

Contact Us

If you have questions or suggestions, please email info@datacollaborative.com. Please send all written communication to: Data Collaborative, Inc, 366 Massachusetts Avenue, Suite 203, Arlington, MA 02474.
	`;


  return (
    <>
      <Divider space={3} />
      <div className="animateInDown">
        <PageTitle header="Privacy Policy" subheader={subheader} />
      </div>
    </>
  )
}
